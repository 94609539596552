import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Col, Row, Typography, Image, Space, Tag, Divider } from "antd";
import { UserAddOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { auth, db } from "../firebaseConfig";
import LikeArticle from "./LikeArticle";
import UserComment from "./UserComment";

const { Title, Text } = Typography;

export default function Article() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [user] = useAuthState(auth);

  useEffect(() => {
    const docRef = doc(db, "Articles", id);
    onSnapshot(docRef, (snapshot) => {
      setArticle({ ...snapshot.data(), id: snapshot.id });
    });
  }, [id]);
  return (
    <div className="container">
      {article && (
        <div className="articleBlock">
          <Title level={2}>{article.title}</Title>
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 16 }}
              style={{ marginBottom: "10px"}}
            >
              <Text type="secondary" style={{ marginTop: "0px" }}>
                <UserAddOutlined />
                {article.createdBy} | Date :{" "}
                {article.createdAt.toDate().toDateString()} | {" "}
                {article.tags?.map( (tag) => (<Tag>{tag}</Tag>))}
              </Text>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
            >
              {user && <LikeArticle id={id} likes={article.likes} />}{" "}
              {article.likes.length}
            </Col>
          </Row>
          <Space direction="vertical" size="large">
            <Row>
              <Col xs={{ span: 24 }}>
                <Image preview={false} src={article.imageUrl} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Text>
                  <div style={{fontSize: "20px"}}
                    dangerouslySetInnerHTML={{ __html: article.description }}
                  />
                </Text>
              </Col>
            </Row>
            <Divider style={{ backgroundColor: "blue" }} />
            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 16 }}>
                <UserComment id={article.id} />
              </Col>
            </Row>
          </Space>
        </div>
      )}
    </div>
  );
}
