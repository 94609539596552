import React, { useState } from "react";
import { signInWithPopup } from "firebase/auth";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, googleProvider } from "../firebaseConfig";
import { signOut } from "firebase/auth";
import { Avatar, Button, Drawer, Dropdown, Space } from "antd";
import {
  MenuOutlined,
  DownOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import logo from "../assets/images/logo786.png";

export default function Header() {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  let navigate = useNavigate();
  const signInWithGoogle = () => {
    signInWithPopup(auth, googleProvider).then((result) => {
      navigate("/");
    });
  };

  const items = [
    {
      label: <Link to="/bookmarks">Bookmarks</Link>,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Link to="#0" onClick={signout}>
          Logout{" "}
        </Link>
      ),
      key: "2",
    },
  ];

  function signout() {
    signOut(auth);
    window.location = "/";
  }

  const [user] = useAuthState(auth);

  return (
    <div className="container">
      {/* header */}
      <div className="header separator">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" height="100" width="160" />
          </Link>
        </div>
        <div className="mobileVisible">
          <Button type="primary" onClick={showDrawer}>
            <MenuOutlined />
          </Button>
          <Drawer placement="right" onClose={onClose} open={visible}>
            <nav>
              <ul>
                {user ? (
                  <>
                    <li>
                      <Avatar src={user.photoURL} alt="" size="small" />
                      {user.displayName}
                    </li>
                    <li>
                      <NavLink to="/bookmarks">Bookmarks</NavLink>
                    </li>
                    <li>
                      <NavLink onClick={signout} to="#0">
                        Signout
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <li>
                    <Button type="default" onClick={signInWithGoogle}>
                      Signin with Google
                    </Button>
                  </li>
                )}
              </ul>
            </nav>
          </Drawer>
        </div>
        <nav className="mobileHidden">
          <ul>
            {user ? (
              <Space size="large">
                <li>
                  <NavLink to="/add-article">
                    <PlusCircleOutlined /> Article{" "}
                  </NavLink>
                </li>
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                >
                  <a onClick={(e) => e.preventDefault()} href="#0">
                    <Space>
                      <Avatar src={user.photoURL} alt="" size="small" />
                      {user.displayName}
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </Space>
            ) : (
              <Button type="default" onClick={signInWithGoogle}>
                Signin with Google
              </Button>
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
}
