import {
  arrayRemove,
  arrayUnion,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { v4 as uuidv4 } from "uuid";
import { auth } from "../firebaseConfig";
import { Row, Col, Tooltip, Avatar, List, Form, Input, Button, Typography } from "antd";
import { Comment } from "@ant-design/compatible";

const { TextArea } = Input;
const { Title } = Typography;

export default function UserComment({ id }) {
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [currentlyLoggedinUser] = useAuthState(auth);
  const commentRef = doc(db, "Articles", id);
  useEffect(() => {
    const docRef = doc(db, "Articles", id);
    onSnapshot(docRef, (snapshot) => {
      setComments(snapshot.data().comments);
    });
  }, [id]);

  const handleChangeComment = (e) => {
    updateDoc(commentRef, {
      comments: arrayUnion({
        user: currentlyLoggedinUser && currentlyLoggedinUser.uid,
        userName: currentlyLoggedinUser && currentlyLoggedinUser.displayName,
        comment: comment,
        createdAt: new Date(),
        commentId: uuidv4(),
      }),
    }).then(() => {
      setComment("");
    });
  };

  // delete comment function
  const handleDeleteComment = (comment) => {
    console.log("comment");
    updateDoc(commentRef, {
      comments: arrayRemove(comment),
    })
      .then((e) => {
        console.log(e);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <Title level={4}>Comments</Title>
      {comments && (
        <div>
          <Row>
            <Col lg={{ span: 24 }}>
              <List
                className="comment-list"
                header={`${comments.length} replies`}
                itemLayout="horizontal"
                dataSource={comments}
                renderItem={(item) => (
                  <li>
                    <Comment
                      author={item.userName}
                      avatar={<Avatar>{item.userName.substring(0, 1)}</Avatar>}
                      datetime={
                        <Tooltip>
                          <span>{item.createdAt.toDate().toDateString()}</span>
                        </Tooltip>
                      }
                      content={item.comment}
                    />
                  </li>
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 24 }}>
              {currentlyLoggedinUser && (
                <div>
                  <Form.Item>
                    <TextArea
                      rows={4}
                      onChange={(e) => {
                        setComment(e.target.value);
                      }}
                      value={comment}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      onClick={handleChangeComment}
                      type="primary"
                    >
                      Add Comment
                    </Button>
                  </Form.Item>
                </div>
              )}
            </Col>
          </Row>
        </div>
      )}
      <div className="uk-container uk-container-large">
        {comments !== null &&
          comments.map(({ commentId, user, comment, userName, createdAt }) => (
            <div key={commentId}>
              <div className="uk-border p-2 mt-2 row">
                <div className="col-1">
                  {user === currentlyLoggedinUser &&
                    currentlyLoggedinUser.uid && (
                      <i
                        className="fa fa-times"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDeleteComment({
                            commentId,
                            user,
                            comment,
                            userName,
                            createdAt,
                          })
                        }
                      ></i>
                    )}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
