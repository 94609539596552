import Hero from '../components/home/Hero';

function AppHome() {
  return (
    <div className='container'>
      <Hero />
    </div>
  );
}

export default AppHome;