// import Articles from "./components/Articles";
import AddArticle from "./components/AddArticle";
import Header from "./components/Header";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Article from "./components/Article";
import Search from "./components/Search"
import "./App.css";
import AppHome from "./pages/Home";

function App() {
  return (
    <div className="container">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<AppHome />} />
          <Route path="/register" element={<Register />} />
          <Route path="/signin" element={<Login />} />
          <Route path="/article/:id" element={<Article />} />
          <Route path="/add-article" element={<AddArticle />} />
          <Route path="/search" element={<Search /> } />
          {/* <Route
            path="/"
            element={
              <div className="row">
                <div className="col-md-12">
                  <Articles />
                </div>             
              </div>
            }
          /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
