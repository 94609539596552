import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCi8J8IJGB2oMvkc7qWrtjhAEBHnLNuGlI",
  authDomain: "annrapid-9d31d.firebaseapp.com",
  projectId: "annrapid-9d31d",
  storageBucket: "annrapid-9d31d.appspot.com",
  messagingSenderId: "1087853216654",
  appId: "1:1087853216654:web:4eb7c33426aa6c630f9adf"
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
