import React, { useState, useEffect } from "react";
import { Col, Row, Divider, Input, Tag } from "antd";
import ArticlePreview from "../ArticlePreview";
// import { Carousel } from "antd"; 
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  limit,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";

// const contentStyle = {
//   margin: 0,
//   height: "400px",
//   color: "#fff",
//   lineHeight: "160px",
//   textAlign: "center",
//   background: "#364d79",
// };

// const data = ["Data", "Dev Ops", "Frontend", "Backend", "Server"];

const tagsData = ["Movies", "Books", "Music", "Sports"];

const { Search } = Input;

// const ArticlePreview = React.lazy(() => import ('../ArticlePreview' )) ;

function Hero() {
  const [articles, setArticles] = useState([]);
  const onSearch = (value, _e, info) => console.log(info?.source, value);

  useEffect(() => {
    const articleRef = collection(db, "Articles");
    const q = query(articleRef, orderBy("createdAt", "desc"), limit(15));
    onSnapshot(q, (snapshot) => {
      const articles = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setArticles(articles);
    });
  }, []);

  return (
    <div className="heroBlock">
      <Row gutter={[24, 24]}>
        {/* carousel */}
        <Col xs={24} lg={18}>
          {/* <Carousel autoplay>
            {console.log(articles.slice(0, 5))}
            {articles.slice(0, 5).map((article) => (
              <div>
                <img
                  style={contentStyle}
                  src={article.imageUrl}
                  alt={article.title}
                />
              </div>
            ))}
          </Carousel> */}
          <span>
            <Divider orientation="left" orientationMargin="0">
              Latest Articles
            </Divider>
            {articles.map((article) => (
              <ArticlePreview key={article.id} article={article} />
            ))}
          </span>
        </Col>
        {/* hero blocks */}
        <Col xs={24} lg={6}>
          <div className="heroBlocks">
            <span>
              <Divider orientation="left" orientationMargin="0">
                Search
              </Divider>
              <Search
                placeholder="input search text"
                onSearch={onSearch}
                enterButton
              />
            </span>
            <span>
              <Divider orientation="left" orientationMargin="0">
                Categories
              </Divider>
              {tagsData.map((tag) => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Hero;
