import React from "react";
import { Link } from "react-router-dom";
import { Divider, Typography, Image, Flex } from "antd";
import { UserAddOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
export default function ArticlePreview({ article }) {
  return (
    <>
      <Link to={`/article/${article.id}`}>
        <Title level={2}>{article.title}</Title>
      </Link>
      <Flex vertical gap={20}>
        <Text type="secondary">
          <UserAddOutlined />
          {article.createdBy} | Date :{" "}
          {article.createdAt.toDate().toDateString()}
        </Text>
        <Image preview={false} src={article.imageUrl} />
      </Flex>
      <Text>
        <div
          dangerouslySetInnerHTML={{
            __html: article.description?.substring(0, 100),
          }}
        />
      </Text>
      <Divider style={{ backgroundColor: "blue" }} />
    </>
  );
}
