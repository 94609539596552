import React, { useState, useEffect } from 'react';
import { db } from "../firebaseConfig";
import { collection, onSnapshot, orderBy, where, query, limit } from "firebase/firestore";
import { useSearchParams, Link} from 'react-router-dom';

const Search = () => {
    const [articles, setArticles] = useState([]);
    const [searchParams] = useSearchParams();
    const search = searchParams.get("title") ;

    useEffect(() => {
        const articleRef = collection(db, "Articles");
        console.log(search);
        const q = query(articleRef, 
                    where("category", "==", search),
                    orderBy("createdAt", "desc"), 
                    limit(15));
        console.log(q);
        onSnapshot(q, (snapshot) => {
            const articles = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setArticles(articles);
        });
    }, [search]);

    return (
        <div>
            <div className="uk-section uk-section-default">
                <div className="uk-container">
                    <div className="uk-grid" data-ukgrid>
                        <div className="uk-width-2-3@m">
                            <h4 className="uk-heading-line uk-text-bold"><span>Search results</span></h4>

                            {articles.slice(5).map(
                                ({
                                    id,
                                    title,
                                    description,
                                    imageUrl,
                                    createdAt,
                                    createdBy,
                                    userId,
                                    likes,
                                    comments,
                                }) => (
                                    <article className="uk-section uk-section-small uk-padding-remove-top" key={id}>
                                        <header>
                                            <h2 className="uk-margin-remove-adjacent uk-text-bold uk-margin-small-bottom"><Link
                                                title="Fusce facilisis tempus magna ac dignissim." className="uk-link-reset"
                                                to={`/article/${id}`}>{title}</Link></h2>
                                            <p className="uk-article-meta">Written on {createdAt.toDate().toDateString()}. Posted in <a href="#0">Blog</a> | <span
                                                data-uk-icon="icon: future"></span> Takes 7 min reading.</p>
                                        </header>
                                        <figure>
                                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAyAAAAEsCAYAAAA7Ldc6AAADuUlEQVR4nO3BgQAAAADDoPlTX+AIVQEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMA3p/4AAaxRls8AAAAASUVORK5CYII="
                                                data-src={imageUrl || "https://placehold.co/600x400"} width="800" height="300"
                                                alt="Alt text" className="lazy" data-uk-img />
                                            <figcaption className="uk-padding-small uk-text-center uk-text-muted">Caption of the image
                                            </figcaption>
                                        </figure>
                                        <p>{description}</p>
                                        <Link to={`/article/${id}`} title="Read More" className="uk-button uk-button-default uk-button-small">READ MORE</Link>
                                        <hr />
                                    </article>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search